.filter-via {
    margin-bottom: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    z-index: 5;
    padding: 5px 0;
    /* background-color: var(--bg); */
}

.filter-via ul {
    display: flex;
    margin: auto;
    gap: 20px;
    justify-content: center;
    width: 300px;
    border-radius: 10px;
    padding: 10px;
    background-color: var(--t-bg);
}

.filter-via li {
    padding: 10px 0;
    border-radius: 10px;
    font-weight: 600;
}

.filter-via a {
    color: white;
    padding: 10px 20px;
}

.filter-via li.active {
    background-color: var(--l-bg);
}

.filter-via li.active a {
    color: rgb(9, 199, 9);
}

.dashboard-search {
    width: 500px;
    border: 1px solid white;
    border-radius: 5px;
    background-color: white;
    padding: 8px 10px;
    display: flex;
    gap: 5px;
    color: black;
    margin: auto;
    margin-bottom: 20px;
}

.dashboard-search input {
    flex-grow: 1;
    width: 100%;
    border: none;
    outline: none;
}

.pull-reqs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 30px;
}

.user-card {
    display: flex;
    transition: .5s;
    flex-direction: column;
    border-radius: 15px;
    font-size: 16px;
    width: 260px;
    height: auto;
    background: #2d2c3bc2;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.user-card .top {
    height: 70px;
    width: 100%;
    padding: 18px 18px 0 18px;
    gap: 5px;
    display: flex;
}

.user-card .top .left {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-card .top .left img {
    width: 100%;
    border-radius: 50%;
}

.user-card .top .right {
    width: 60%;
    height: 100%;
    margin: 0 5px;
    flex-grow: 1;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    font-size: 16px;
}

.user-card .top .right h3, .user-card .top .right h5{
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
    width: 100%;
}

.user-card .top .right h4 {
    color: #ddd;
}

.user-card .bottom {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    gap: 10px;
    padding: 10px;
}

.time-created {
    background-color: rgb(31, 27, 43);
    font-size: 13px;
    padding: 10px;
    border-radius: 10px;
}

.user-card .bottom p {
    padding: 10px;
    border-radius: 10px;
}

.pr-status {
    background-color: #17161e;
    padding: 10px;
    font-size: 14px;
    border-radius: 5px
}

.pr-status.merged {
    color: rgb(23, 247, 23);
}

.pr-status.pending {
    color: #dfb11b;
}

.pr-status.denied {
    color: #ff2c02;
}

.user-card .bottom .view-btn {
    border-radius: 10px;
    padding: 7px;
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    background-color: white;
    color: black;
    text-align: center;
}

.user-card:hover{
    background-color: #1f1f22;
}

/* repo-card */

.all-repos {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
    align-items: stretch;
    padding-bottom: 30px;
}

.repo-card {
    width: 250px;
    height: auto;
    background: #2d2c3bc2;
    padding: 18px;
    border-radius: 15px;
    font-size: 16px;
}

.repo-card h1 {
    font-size: 20px;
    text-align: center;
    color: white;
    border-bottom: 1px solid green;
    padding-bottom: 10px;
}

.repo-card .tech-stack-title {
    font-size: 16px;
    margin: 10px;

}

.repo-card .tech-stack {
    margin-left: 20px;
    line-height: 28px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
}

.repo-card .repo-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.repo-card .repo-link a {
    color: white;
    border-radius: 10px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    text-align: center;
    background-color: var(--bg);
    font-weight: 600;
}

.repo-card .repo-link a:nth-child(2) {
    background-color: var(--l-white);
    color: var(--bg);
}

.repo-card .repo-link a:hover {
    color: #09c409;
}

.repo-card .dets {
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.repo-title {
    text-align: center;
    margin: 20px 0;
}

.repo-card .creator-name {
    text-align: end;
    margin: 10px;
}

.repo-email-copy{
    font-size: 13px;
    text-align: end;
    cursor: pointer;
}

.repo-email-copy:hover{
    color: #ddd;
}


@media all and (max-width : 426px) {
    .pull-reqs {
        padding: 10px;
    }

    .dashboard-search {
        width: 85vw;
        align-self: center;
    }

    .repo-card,
    .user-card {
        width: 85vw;
    }

    .filter-via {
        top: 50px;
        padding: 5px;
    }

    .filter-via ul {
        width: 250px;
    }

    .filter-via li {
        padding: 10px 0;
        border-radius: 10px;
        font-weight: 600;
        font-size: 14px;
    }
}


.loading-animation {
    font-size: x-large;
    display: flex;
    justify-content: center;
}

.loader {
    --size: 80px;
    /* Size of the loader */
    width: var(--size);
    height: var(--size);
    margin: 50px auto;
    /* Center the loader */
    border: 8px solid rgba(255, 255, 255, 0.1);
    /* Thin border around the loader */
    border-left: 8px solid #fff;
    /* Blue border for the rotation */
    border-radius: 50%;
    /* Make it circular */
    animation: spin 1s linear infinite;
    /* Animation for rotation */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.profile-pic{
    border-radius: 50%;
}

.profile-pic-container{
    display: flex;
}

.repo-name{
    margin-left: auto;
}