aside{
    width: 200px;
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    height: 100vh;
    z-index: 3;
    background-color: var(--l-bg);
    transition: transform .5s ease;
    overflow: hidden;
}

aside.open{
    transform: translateX(0);
}

aside.closed{
    transform: translateX(100%);
}

aside .side-bar-close{
    align-self: end;
    padding: 20px;
    cursor: pointer;
    font-size: larger;
    margin-right: 20px;
}

aside .side-bar-close:hover{
    color: green;
}

aside .nav{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
}

aside .nav li{
    height: 60px;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--l-white);
}

aside .nav li:hover a{
    color: rgb(12, 220, 12);
}

aside .nav li:hover{
    background-color: var(--bg);
}

aside .nav li a{
    padding-left: 20px;
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

aside .nav li a.active, .login-side-bar:hover , .login-side-bar:hover button{
    color: rgb(12, 220, 12);
}

.login-side-bar div{
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0 0 0 20px;
    height: 60px;
    width: 100%;
    animation: none;
    border-bottom: 1px solid var(--l-white);
    background-color: transparent;
    border-radius: 0;
    color: white;
}

.login-side-bar div:hover{
    background-color: transparent;
}

/* aside .nav li:hover {
    box-shadow: 0 0 30px 5px #282936;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

aside .nav li:hover::before {
    -webkit-animation: sh02 0.5s 0s linear;
    -moz-animation: sh02 0.5s 0s linear;
    animation: sh02 0.5s 0s linear;
}

aside .nav li::before {
    content: '';
    display: block;
    width: 0px;
    height: 86%;
    position: absolute;
    top: 7%;
    left: 0%;
    opacity: 0;
    background: #fff;
    box-shadow: 0 0 50px 30px #fff;
    -webkit-transform: skewX(-20deg);
    -moz-transform: skewX(-20deg);
    -ms-transform: skewX(-20deg);
    -o-transform: skewX(-20deg);
    transform: skewX(-20deg);
}



aside .nav li:active {
    box-shadow: 0 0 0 0 transparent;
    -webkit-transition: box-shadow 0.2s ease-in;
    -moz-transition: box-shadow 0.2s ease-in;
    transition: box-shadow 0.2s ease-in;
}

@keyframes sh02 {
    from {
        opacity: 0;
        left: 0%;
    }
    
    50% {
        opacity: 1;
    }

    to {
        opacity: 0;
        left: 100%;
    }
} */

.login-side-bar .login-home{
    align-items: start;
}

@media all and (max-width:768px) {
    aside{
        display: flex;
    }
}


@media all and (max-width:426px) {
    aside.open{
        width: 200px;
    }
    aside.closed{
        width: 0;
    }
    aside .side-bar-close{
        margin-right: 10px;
    }
}