.badge{
    background-color: #262626f1;
    width: 100%;
    display: flex;
    box-shadow: 0 0 20px 0px black;
    min-height: 130px;
    cursor: pointer;
    overflow: hidden;
    transition: .5s;
    border-radius: 5px;
}

.badge .left{
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.badge img{
    width: 75%;
    border-radius: 50%;
}

.badge .right{
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
}

.badge .right a{
    color: #5dcc5d;
}

.badge .right a:hover{
    text-decoration: underline;
}

.badge .right p{
    margin: 0;
}

.badge .right .awarded-by{
    margin: 0;
    font-size: 12px;
    margin-top: 4px;
}

.badge .right .awarded-time{
    font-size: 14px;
    margin-top: auto;
    margin-bottom: 5px;
}

.all-badges h1{
    text-align: center;
    margin: 20px;
}

.badges-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 30px;
}

.badge .tr-cover {
    position: absolute;
    transform: rotate(45deg);
    background-color: #565656;
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -8px;
    right: -41%;
    height: 41px;
}

.badge span.time{
    position: absolute;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 10px;
    font-size: 13px;
}

.badge span.time .circle{
    width: 4px;
    margin-right: 5px;
    color: #00ff00;
}

.badges .partners-div{
    height: auto;
    padding: 20px 0;
}

.badge-page{
    /* background-color: var(--bg); */
    flex-grow: 1;
    padding: 30px;
    height: 100%;
    min-height: 100%;
}

.badge-page .verify-note{
    width: 80%;
    padding: 20px;
    border: 1px solid #15803d;
    border-left: 7px solid #15803d;
    background-color: #dcfce7;
    color: #15803d;
    margin: auto;
}

.badge-page .verify-note a{
    color: #116e33;
    font-weight: 600;
    text-decoration: underline;
}

.badge-page .dets{
    display: flex;
    justify-content: center;
    width: 90%;
    margin: auto;
    padding: 30px 0;
}

.badge-page .dets .right{
    padding: 20px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.badge-page .dets .right img{
    border-radius: 20px;
    width: 90%;
    background-color: white;
}

.badge-page .dets .left{
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
}

.badge-page .dets .left .logo{
    width: 40px;
    height: 40px;
    display: flex;
}

.badge-page .dets .left .title{
    display: flex;
    align-items: center;
    gap: 5px;
}

.badge-page .dets .left .title .light{
    color: #ddd;
}

.badge-page .dets .left .logo img{
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 50%;
}

.badge-page .dets .left .verified{
    padding: 8px 20px;
    font-size: 14px;
    border: 2px solid #05de55;
    color :#0ade58;
    border-radius: 10px;
    font-weight: 600;
}

.badge-page h1{
    margin: 10px 0;
}
.badge-page .hashs{
    margin-top: 20px;
}

.badge-page .hashs .hash{
    padding: 5px 10px;
    color: #3b82f6;
    border: 1px solid #3b82f6;
    margin-right: 10px;
    font-size: 14px;
    border-radius: 10px;
}

.badge-page .desc{
    margin-top: 20px;
    margin-bottom: 10px;
}

.badge-page .earning-criteria{
    color: #ddd;
    margin-top: 30px;
    margin-bottom: 10px;
}

.share-now{
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #3b82f6;
    text-align: center;
    color: white;
}

.share-now:hover{
    background-color: #1c6ae9;
}

.badge-grid{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 0 20px 0px black;
    padding: 10px;
    border-radius: 15px;
    position: relative;
    background-color: #46464782;
    transition: .5s;
    padding-bottom: 40px;
    padding-top: 15px;
}

.badges h1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.badges h1 a{
    color: white;
    transition: .5s;
}

.badges h1 a:hover{
    color: rgb(180, 180, 182);
}

.badge-grid:hover{
    transform: scale(1.03);
    background-color: #222222;

}

.badge-grid p:first-child{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    position: relative;
    z-index: 10;
}

.badge-grid .badge-reward{
    width: 30px;
    height: 30px;
}

.badge-grid span.name{
    color: #00ff00;
    font-weight: 600;
    margin-right: 5px;
    padding: 0 10px;
}

.badge-grid .image-box{
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.badge-grid .image-box img{
    width: 160px;
    height: 160px;
    box-shadow: 0 0 87px -14px #030303;
    border-radius: 6px;
}

.home .badge-grid p.badge-name{
    padding: 10px 15px;
    border-radius: 10px;
    margin: 0;
    margin-top: 10px;
    font-weight: 800;
    background-color: #201f23;
}

.home .badge-grid p{
    font-size: 15px;
    padding: 0;
    margin: 0;
    margin-top: 5px;
    text-align: center;
}

.badge-grid::before, .badge-grid::after {
    content: '';
    position: absolute;
    transform: rotate(45deg);
    background-color: #565656;
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41px;
}

.badge-grid::before{
    top: -8px;
    right: -41%;
}

.badge-grid::after{
    bottom: -8px;
    left: -41%;
}


.badge-grid span.time{
    position: absolute;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 10px;
    font-size: 13px;
}

.badge-grid span.time .circle{
    width: 4px;
    margin-right: 5px;
    color: #00ff00;
}


@media all and (max-width:768px){
        .badge-page .dets{
            flex-direction: column;
        }
        .badge-page .dets .left, .badge-page .dets .right{
            width: 100%;
        }
        .badge-page .verify-note{
            width: 95%;
            padding: 10px;
            font-size: 14px;
        }
}

@media all and (max-width:468px) {
    .badge-page{
        padding: 0;
    }
}