@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    letter-spacing: .2px;
}

:root{
    --white: #fff;
    --l-white: #ddd;
    --bg: #17161e;
    --l-bg: #201f2a;
    --t-bg:#17161ee9;
}

body{
    color: var(--white);
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'Inter', Times, serif;
    background-color: var(--bg);
}

#root{
    min-height:100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main{
    padding-top: 65px;
    transition: .5s;
    position: relative;
    z-index: 0;
    flex-grow: 1;
}

.main.blur{
    filter: blur(2px);
}
