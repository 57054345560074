.area {
    background: transparent;
    width: 100%;
    height: 100vh;
    left: 0;
    z-index: -1;
    top: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: #595773c2;
    animation: animate 15s linear infinite;
    bottom: -150px;

}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 2s;
}


.circles li:nth-child(2) {
    left: 10%;
    width: 30px;
    height: 30px;
    animation-delay: 2s;
    animation-duration: 8s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 10s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 35s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 35px;
    height: 35px;
    animation-delay: 2s;
    animation-duration: 25s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

.circles li:nth-child(11) {
    left: 55%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
    animation-duration: 14s;
}

.circles li:nth-child(12) {
    left: 45%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 17s;
}

@media(max-width: 525px) {
    .circles li:nth-child(10) {
        width: 70px;
        height: 70px;
    }

    .circles li:nth-child(7) {
        width: 60px;
        height: 60px;
    }

    .circles li:nth-child(6) {
        width: 70px;
        height: 70px;
    }
}

@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}