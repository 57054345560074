.profile{
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}

.profile h1.title{
    text-align: center;
    width: 100%;
}

.profile .pr-n-badges{
    gap: 10px;
    display: flex;
}

.profile .dets{
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
}

.profile .my-pull-reqs{
    width: 100%;
}

.profile .my-earned-badges{
    width: 100%;
}

.profile .dets .user-card{
    width: 100%;
    height: auto;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
}

.profile .user-card .top .right h5, .profile .user-card .top .right h5 span, .profile .user-card .top .right h3{
    letter-spacing: 1px;
}

.profile .dets .user-card .bottom .time-created , .profile .dets .user-card .bottom .pr-status{
    background-color: #1f1b2b;
    padding: 10px;
}

.profile .dets .user-card .top{
    height: auto;
    width: auto;
    flex-shrink: 1;
    align-items: center;
    padding-bottom: 10px;
}

.profile .dets .user-card .top .left{
    width: 100px;
}

.profile .dets .user-card .bottom{
    width: auto;
    padding-right: 0;
    /* width: 55%; */
    justify-content: space-between;
    flex-direction: row;
}

.profile .dets .user-card .top .right{
    width: auto;
    margin: auto;
    flex-grow: 0;
    margin: 0 10px;
}

.profile .dets a{
    color: white;
}

.profile .dets a:hover{
    text-decoration: underline;
    color: rgb(45, 135, 213);
}

.profile div .title{
    padding: 20px;
    background-color: #232227;
}

.profile h2.title{
    margin: 20px;
    padding: 20px 0;
    text-align: center;
}

.profile h4{
    text-align: center;
    margin: 0 20px;
}

.profile .name{
    color: #17f717;
}


@media all and (max-width:1024px){
    .profile .dets .user-card .bottom{
        flex-grow: 1;
    }
}


@media all and (max-width:768px){
    .profile .dets{
        flex-direction: column;
        gap: 20px;
        padding: 5px;
    }
    
    .profile h2.title{
        margin: 10px 0;
    }

    .profile .dets p{
        text-align: center;
    }

    .profile .pr-n-badges{
        flex-direction: column;
    }
    .profile .dets .user-card{
        flex-direction: column;
    }
    .profile .dets .user-card .bottom, .profile .dets .user-card .top{
        width: 100%;
        justify-content: center;
    }
}

@media all and (max-width:425px){
    .profile .dets .user-card .bottom{
        flex-direction: column;
    }
}