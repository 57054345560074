.project-list{
    background-color: #3c3a4ec1;
    width: 90%;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
}

.project-list.title{
    background-color: transparent;
    padding: 0 20px;
}

.all-projects h1{
    position: relative;
    text-align: center;
    margin: 20px;
}

.projects-list, .projects-grid{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 30px;
}

.projects-grid{
    display: none;
}

.add-project-idea {
    color: black;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    font-size: 14px;
    background-color: rgb(69 189 69);
    padding: 10px;
    border-radius: 10px;
}

.project-item:hover {
    transform: translateY(-10px);
    background-color: #201f29c1;
}

.project-title, .project-domain, .project-tech {
    margin: 10px 0;
    color: #fff;
    font-size: 1.1em;
    text-align: center;
    width: 100%;
}

.project-grid {
    background-color: #3c3a4ec1;
    padding: 10px;
    min-width: 80%;
    margin: 20px;
    border-radius: 10px;
    display: grid;
    animation: fadeInUp 0.5s ease-in-out;
    transition: transform 0.3s;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: 
        "owner owner"
        "domain domain"
        "title title"
        "tech tech"
        "link link";
    row-gap: 5px;
    row-gap: 15px;
    font-size: 14px;
}

.project-owner {
    grid-area: owner;
    color: #fff;
    background-color: #1f1f25c1;
    padding: 10px;
    text-align: left;
    border-radius: 5px;
}

.project-domain {
    grid-area: domain;
    width: 100%;
    text-align: center !important;
    color: #fff;
    text-decoration: underline;
}

.project-title {
    grid-area: title;
    color: #fff;
    text-align: center;
}

.project-tech {
    grid-area: tech;
    color: #fff;
    text-align: center;
    display: none;
}

.project-link {
    grid-area: link;
    width: 90%;
    text-align: center;
    margin-top: 20px;
}

.project-link button{
    width: 100%;
    justify-content: center;
}


.project-grid .project-link{
    margin: auto;
}

.project-grid p{
    text-align: center;
    margin: auto;
}

.view-project-list-title{
    padding: 10px 20px;
    text-wrap: nowrap;
}


@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.project-form.contact-form .contact-me-form{
    margin-top: 30px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}

.project-form.contact-form .contact-me-form .part{
    width: 45%;
}

.project-form.contact-form .submit-btn{
    width: 40%;
    justify-content: center;
    align-items: center;
}












.project-detail {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.project-detail .project-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.project-detail .title {
    font-size: 2em;
    font-weight: bold;
    color: white;
    margin: auto;
}

.project-detail .project-info {
    margin-bottom: 20px;
}

.project-detail .info-block {
    margin-bottom: 24px;
    display: flex;
    column-gap: 20px;
    flex-wrap: wrap;
}

.project-detail .info-block h2 {
    margin: 0;
    font-size: 1.2em;
    color: #03DAC6;
}

.project-detail .info-block p {
    margin: 5px 0 0;
    color: #E0E0E0;
}

.project-detail .info-block a {
    color: #BB86FC;
    text-decoration: none;
    transition: color 0.3s;
}

.project-detail .info-block a:hover {
    color: white;
    text-decoration: underline;
}

.project-detail .description {
    margin-top: 20px;
}

.project-detail .description h2 {
    font-size: 1.5em;
    color: #03DAC6;
}

.project-detail .description p {
    color: #E0E0E0;
    line-height: 1.6;
}

.loading-project{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    /* backdrop-filter: blur(10px); */
    background-color: rgba(0, 0, 0, 0.285);
}

.loading-project .loading {
    width: 60px;
    height: 60px;
    border: 10px solid #f3f3f3; /* Light gray border */
    border-top: 10px solid #3498db; /* Blue border on top */
    border-radius: 50%; /* Make it a circle */
    animation: spin 2s linear infinite; /* Add animation */
}

/* Keyframes for the spin animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}





@media all and (max-width:700px) {
    .project-form.contact-form .contact-me-form .part{
        width: 90%;
    }
}


@media all and (max-width:468px) {
    .projects-list{
        display: none;
    }

    .projects-grid{
        display: flex;
    }

    .all-projects h1{
        text-align: start;
    }
    .dashboard-search {
        width: 85vw;
        align-self: center;
    }
    .project-detail{
        font-size: 13px
    }
}

@media all and (max-width:375px) {
    .add-project-idea span{
        display: none;
    }
}