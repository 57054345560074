:root{
    --badge-number: 3;
}

.home {
    display: flex;
    flex-direction: column;
}

.home .title {
    width: 100%;
    text-align: center;
    font-size: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #18c718;
    letter-spacing: 1px;
}

.hero {
    display: flex;
    min-height: calc(100vh - 300px);
    height: auto;
    padding: 0 20px;
}

.hero .left {
    width: 50%;
    height: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding-left: 30px;
}

.hero .left h1 {
    font-size: 40px;
    text-align: center;
}

.hero .left h3 {
    margin: 30px 0;
    text-align: center;
}

.hero .left p {
    color: #10df10;
    margin-top: 15px;
    font-weight: 600;
}

.hero .links {
    display: flex;
    gap: 10px;
}

.hero .links .login-home:hover{
    background-color: #ffffff;
}

.hero .links div button{
    color: rgb(0, 0, 0);
}

.hero .links a:first-child {
    color: black;
    background-color: transparent;

}

.hero .links .login-home:hover a {
    color: rgb(13, 194, 13);
}

.hero .right {
    width: 50%;
}

.hero .right:nth-child(2){
    margin-bottom: 10%;
}

.home section {
    margin: 30px 60px;
    padding: 20px;
    letter-spacing: 1px;
    border-radius: 10px;
}

.home section h1 {
    color: white;
    margin-bottom: 20px;
}

.home section p {
    color: #ddd;
    margin-left: 20px;
    font-size: 18px;
}

.home section .partner-name p{
    margin-left: 0;
}

.home .what-soc {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #2b2a37c1;
}

.home .what-soc.half{
    margin: 0;
}

.home .what-soc span.green {
    color: #18c718;
}

.home .what-soc .quote {
    width: 50%;
    align-self: center;
    padding: 20px;
    border: 1px solid #534e6f;
    border-left: 5px solid #534e6f;
    border-radius: 0 5px 5px 0;
}

.what-soc h1 {
    display: flex;
    align-items: center;
}

.what-soc h1 img {
    width: 50px;
    height: 50px;
    padding: 5px;
    border-radius: 50%;
    margin-right: 20px;
    background-color: aliceblue;
}

.hero .login-home {
    cursor: pointer;
    border: none;
    width: 100%;
    padding: 10px 15px;
    height: 100%;
    font-size: 16px;
    font-weight: 600;
    background-color: #d2d2d2;
    border-radius: 10px;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


.partners-container {
    border-radius: 15px;
    width: 100%;
    position: relative;
    margin-top: 30px;
    display: flex;
    align-items: center;
    overflow-x: hidden;
}

.partners-div{
    width: calc(300px * 16);
}

.our-team-div{
    width: calc(260px * 14);
}

.slider{
    display: flex;
    gap: 30px;
    height: auto;
    padding: 20px 30px;
    animation: 40s scroll linear infinite;
}
/* 
.slider:hover{
    animation-play-state: paused;
} */

.home section.partners, .home section.our-team{
    margin: 30px 10px;
}

.home section.badges h1{
    padding: 0 30px;
}

@keyframes scroll {
    from{
        transform: translateX(0);
    }to{
        transform: translateX(calc(-300px * 8));
    }
}

.partners-div::-webkit-scrollbar{
    width: 0px;
}

.partner-slide{
    min-width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    perspective: 100px;
    transition: transform 1s;
}

.partner-slide:hover{
    transform: translateZ(10px);
}

.partner-slide img{
    width: 100%;
    border-radius: 20px;
}

.partner-slide .partner-name{
    text-align: center;
    margin-top: 20px;
}

.partner-left, .partner-right{
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--l-bg);
}

.partner-left:hover, .partner-right:hover{
    background-color: var(--bg);
}

.partner-left{
    left: -30px;
}

.partner-right{
    right: -30px;
}

.partner-name{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.partner-name .mem-name{
    color: #10df10;
}

.partners .partner-img .csau{
    filter: drop-shadow(1.2px white);
}

.what-soc-n-badges{
    display: flex;
    justify-content: space-between;
    margin: 30px 60px;
}

.what-soc-n-badges .what-soc{
    width: 50%;
}

.what-soc-n-badges .badges{
    width: 50%;
    margin: 0;
    max-height: 400px;
    overflow: hidden;
}

.what-soc-n-badges .badges .badge-slider{
    height: calc(100px * var(--badge-number));
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    animation: 20s badge-scroll linear infinite;
}

.what-soc-n-badges .badges .badge-slider:hover{
    animation-play-state: paused;
}

@keyframes badge-scroll {
    from{
        transform: translateY(0);
    }to{
        transform: translateY(calc(-100px * var(--badge-number)));
    }
}

.view-all-badge{
    color: white;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    padding: 20px 0;
    background-color: #232227;
}

.view-all-badge:hover{
    background-color: #302f36;
}

@media all and (max-width:768px) {
    .home .title p {
        font-size: 40px;
        padding: 0 100px;
    }

    .home .hero {
        padding: 30px;
        margin-top: 20px;
        flex-direction: column-reverse;
    }

    .hero .left, .hero .right{
        width: 100%;
    }

    .hero .left h1 {
        font-size: 30px;
    }

    .hero .left p {
        text-align: center;
    }
    section.partners{
        padding: 0;
        margin: 30px;
    }
    .what-soc-n-badges{
        flex-direction: column;
        gap: 20px;
    }
    .what-soc-n-badges .what-soc, .what-soc-n-badges .badges{
        width: 100%;
    }
    .home .what-soc-n-badges .badges{
        max-height: 400px;
        margin: 0;
    }
}

@media all and (max-width:426px) {
    .hero {
        flex-direction: column-reverse;
    }

    .hero .left {
        width: 100%;
        gap: 0;
        padding-left: 0;
    }

    .home .hero {
        padding: 0 10px;
        margin-top: 0;
    }

    .home .title p {
        font-size: 28px;
        padding: 0 10px;
    }

    .hero .left h1 {
        font-size: 25px;
    }

    .home section {
        margin: 10px;
        padding: 10px;
    }

    .home section h1 {
        margin-bottom: 0;
    }

    .home .what-soc {
        margin-top: 30px;
    }

    .home section p {
        font-size: 16px;
        margin-left: 5px;
    }

    .home .what-soc .quote {
        width: 90%;
    }

    .partner-left, .partner-right{
        width: 30px;
        height: 30px;
    }
    .partner-left{
        left: -15px;
    }
    
    .partner-right{
        right: -15px;
    }

.hero .links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


    .partner-slide{
        min-width: 200px;
        width: 200px;
    }
    .partners-div::-webkit-scrollbar{
        width: auto;
    }
    .what-soc-n-badges{
        margin: 10px;
    }
    .what-soc-n-badges .badges .badge-slider{
        padding: 0;
    }
}

.google-btn, .github-btn, .dashboard-btn {
    display: flex;
    gap: 5px;
    position: relative;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    background: #fff;
    color: #000;
    overflow: hidden;
    box-shadow: 0 0 0 0 transparent;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

.github-btn:hover, .google-btn:hover, .dashboard-btn:hover {
    background: #fff;
    box-shadow: 0 0 30px 5px #282936;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.github-btn:hover::before, .google-btn:hover::before, .dashboard-btn:hover::before {
    -webkit-animation: sh02 0.5s 0s linear;
    -moz-animation: sh02 0.5s 0s linear;
    animation: sh02 0.5s 0s linear;
}

.github-btn::before, .google-btn::before, .dashboard-btn::before {
    content: '';
    display: block;
    width: 0px;
    height: 86%;
    position: absolute;
    top: 7%;
    left: 0%;
    opacity: 0;
    background: #fff;
    box-shadow: 0 0 50px 30px #fff;
    -webkit-transform: skewX(-20deg);
    -moz-transform: skewX(-20deg);
    -ms-transform: skewX(-20deg);
    -o-transform: skewX(-20deg);
    transform: skewX(-20deg);
}

.github-btn:active, .google-btn:active, .dashboard-btn:active {
    box-shadow: 0 0 0 0 transparent;
    -webkit-transition: box-shadow 0.2s ease-in;
    -moz-transition: box-shadow 0.2s ease-in;
    transition: box-shadow 0.2s ease-in;
}

@keyframes sh02 {
    from {
        opacity: 0;
        left: 0%;
    }

    50% {
        opacity: 1;
    }

    to {
        opacity: 0;
        left: 100%;
    }
}
