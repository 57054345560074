.leaderboard{
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.leaderboard .title{
    width: 100%;
    text-align: center;
}

.leaderboard-table{
    margin-top: 60px;
    width: 80%;
    background-color: #222227c1;
    padding: 20px;
    border-radius: 10px;
    box-shadow: inset 0 0 20px 8px #4548517d;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.leaderboard-table table{
    width: 100%;
}

.leaderboard-table table thead th{
    color: orange;
}

/* .leaderboard-table tbody tr:first-child td, .leaderboard-table tbody tr:first-child td a{
    color: rgb(0, 255, 0);
    font-weight: 600;
} */

.leaderboard-table table tr td, .leaderboard-table table tr th {
     padding: 15px;
}

.leaderboard-table table tr td span{
    padding: 5px;
    border-radius: 5px;
}

.leaderboard-table table tr td span.first{
     background-color: #3b310c;
     color: #eac684;
}

.leaderboard-table table tr td span.second{
     background-color: #191d25;
     color: #b7b8cd;
}

.leaderboard-table table tr td span.third{
     background-color: #191512;
     color: #ad978c;
}

.leaderboard-table table tr td:last-child{
    padding: 0;
}

.leaderboard-table table tr td a{
    color: white;
    display: block;
    cursor: pointer;
    border-radius: 10px;
    width: 100%;
    padding: 15px;
    /* background-color: var(--t-bg); */
    /* padding: 5px 20px; */
}

.leaderboard-table table tbody tr:hover{
    background-color: var(--bg);
}

.leaderboard-table .my-rank{
    background-color: #012e57;
}

@media all and (max-width:768px) {
    .leaderboard-table table{
        font-size: 14px;
    }
    .leaderboard-table{
        width: 100%;
    }
    .leaderboard-table table tr td, .leaderboard-table table tr th {
        padding: 15px 10px;
    }
    .leaderboard-table table tr td a{
        padding: 5px 15px;
    }
}

@media all and (max-width:425px) {
    .leaderboard{
        padding: 20px 0;
    }
    .leaderboard-table table tr td, .leaderboard-table table tr th{
        padding: 5px;
        line-height: 25px;
    }
}